<template>
  <div class="page1">
    <div class="content">
      <div class="searchWrapper" @keydown.enter.prevent="searchData">
        <el-form :inline="true" label-position="right" :model="formInline" class="demo-form-inline">
          <div class="search_box_title">{{ $t("searchModule.Query_Table") }}</div>
          <div class="col_box">
            <div class="col_left">
              <el-form-item :label="$t('searchModule.region')" prop="areaId">
                <a-cascader @change="handleAreaChange" ref="cascader"></a-cascader>
              </el-form-item>
              <el-form-item :label="$t('searchModule.park_name')">
                <el-autocomplete
                  class="inline-input"
                  v-model="modelvalue"
                  :fetch-suggestions="querySearchAsync"
                  :placeholder="$t('placeholder.Please_enter_the_content')"
                  value-key="parkName"
                  :trigger-on-focus="false"
                  @select="handleSelect"
                ></el-autocomplete>
              </el-form-item>
              <el-form-item :label="$t('searchModule.parking_code')">
                <el-input
                  v-model.trim="formInline.parkAlias"
                  :placeholder="$t('placeholder.Please_enter_the_parking_code')"
                ></el-input>
              </el-form-item>
              <el-form-item :label="$t('searchModule.Merchant_Name')" prop="operationId">
                <el-select v-model.trim="formInline.operationId" filterable size="15">
                  <el-option :label="$t('searchModule.all')" value></el-option>
                  <el-option
                    :label="value.operationName"
                    :value="value.operationId"
                    :key="value.operationId"
                    v-for="value in tenantList"
                  ></el-option>
                </el-select>
              </el-form-item>
              <el-form-item :label="$t('searchModule.Commercial_status')" labelWidth="10">
                <el-select
                  v-model.trim="formInline.commercialStatus"
                  :placeholder="$t('placeholder.please_choose')"
                  filterable
                >
                  <el-option :label="$t('searchModule.all')" value></el-option>
                  <el-option
                    :label="value.desc"
                    :value="value.code + ''"
                    :key="value.code"
                    v-for="value in commercialStatus"
                  ></el-option>
                </el-select>
              </el-form-item>
              <el-form-item :label="$t('searchModule.Access_method')" labelWidth="10">
                <el-select v-model.trim="formInline.parkAccessType" :placeholder="$t('placeholder.please_choose')" filterable>
                  <el-option :label="$t('searchModule.all')" value=""></el-option>
                  <el-option :label="$t('searchModule.MB_Cloud_Box')" value="1"></el-option>
                  <el-option :label="$t('searchModule.Platform_to_Platform')" value="2"></el-option>
                  <el-option :label="$t('searchModule.Lightweight_Cloud_Box')" value="3"></el-option>
                </el-select>
              </el-form-item>
            </div>
            <div class="col_right">
              <el-button
                type="primary"
                icon="el-icon-search"
                v-if="$route.meta.authority.button.query"
                @click="
                  page = 1;
                  searchData();
                "
                :loading="loading"
                >{{ $t('button.search') }}</el-button
              >
              <el-button type="info" icon="el-icon-delete" @click="resetForm()">{{ $t('button.reset') }}</el-button>
            </div>
          </div>
          <div
            class="col_box_boder"
            v-if="$route.meta.authority.button.add || $route.meta.authority.button.evaluate"
          ></div>
          <div
            class="col_box h44"
            v-if="$route.meta.authority.button.add || $route.meta.authority.button.evaluate"
          >
            <div class="col_left">
              <el-button
                type="primary"
                icon="el-icon-plus"
                @click="handleCommand2('b')"
                v-if="$route.meta.authority.button.add"
                >{{ $t('button.addto') }}</el-button
              >
              <el-button
                type="info"
                icon="el-icon-document-copy"
                plain
                @click="setComment"
                v-if="$route.meta.authority.button.evaluate"
                >{{ $t('button.Evaluate_batch_configuration') }}</el-button
              >
              <el-button
                v-if="$route.meta.authority.button.evaluate"
                type="info"
                plain
                icon="el-icon-upload"
                @click="$refs.importFile.dialogVisible = true"
                :loading="loading"
                >{{ $t('button.batch_import') }}</el-button
              >
            </div>
          </div>
        </el-form>
      </div>
      <!--列表区域-->
      <div class="tableWrapper bgFFF paddingB10">
        <el-table
          header-cell-class-name="header-call-style"
          v-loading="loading"
          :data="tableData"
        >
          <el-table-column
            type="index"
            :label="$t('list.index')"
            width="70"
            align="center"
          ></el-table-column>
          <el-table-column
            :prop="item.prop"
            :label="item.label"
            :width="item.width"
            v-for="item in tableCols"
            :key="item.prop"
            :formatter="item.formatter"
            align="center"
          ></el-table-column>
          <el-table-column
            :label="$t('button.operation')"
            v-if="
              $route.meta.authority.button.view ||
              $route.meta.authority.button.edit ||
              $route.meta.authority.button.configure ||
              $route.meta.authority.button.qr
            "
            align="center"
            width="80"
          >
            <template slot-scope="scope">
              <AuthorityComponent
                ComponentName="el-dropdown"
                align="center"
                v-show="
                  $route.meta.authority.button.view ||
                  $route.meta.authority.button.edit ||
                  $route.meta.authority.button.configure
                "
                @command="handleCommand($event, scope.row)"
                width="80"
              >
                <el-button type="text" size="small" style="padding: 0"
                  >{{ $t("button.operation") }}<i class="el-icon-arrow-down"
                /></el-button>
                <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item command="b" v-if="$route.meta.authority.button.view">{{
                    $t("button.view")
                  }}</el-dropdown-item>
                  <el-dropdown-item command="a" v-if="$route.meta.authority.button.edit">{{
                    $t("button.edit")
                  }}</el-dropdown-item>
                  <el-dropdown-item
                    :command="scope.row.parkAccessType !== 3 ? 'c' : 'h'"
                    v-if="$route.meta.authority.button.configure"
                    >{{ $t("button.Parking_Lot_Configuration") }}</el-dropdown-item
                  >
                  <el-dropdown-item command="d" v-if="$route.meta.authority.button.qr">{{
                    $t("button.QR_code_download")
                  }}</el-dropdown-item>
                  <el-dropdown-item command="e" v-if="$route.meta.authority.button.sync">{{
                    $t("button.sync_local")
                  }}</el-dropdown-item>
                  <el-dropdown-item
                    command="f"
                    v-if="
                      scope.row.parkSystemType != 0 &&
                      (scope.row.slaveRelation == 0 || scope.row.slaveRelation == 1)
                    "
                    >{{ $t("button.Modify_remaining_berths") }}</el-dropdown-item
                  >
                </el-dropdown-menu>
              </AuthorityComponent>
            </template>
          </el-table-column>
        </el-table>
        <!--分页器-->
        <div class="pagerWrapper">
          <div class="block">
            <el-pagination
              @current-change="handleCurrentChange"
              :current-page="page"
              :page-size="pageSize"
              layout="total, prev, pager, next, jumper"
              :total="total"
            ></el-pagination>
          </div>
        </div>
        <!--评价配置-->
        <comment-setup ref="commentSet" @searchData="searchData"></comment-setup>
      </div>
      <el-dialog
        :title="$t('pop_up.Modify_the_remaining_parking_spaces')"
        :visible.sync="dialogFormVisible"
        width="40%"
        class="upBerthNum"
        :close-on-click-modal="false"
      >
        <template v-if="parkAccessType === 3">
          <el-table
            :data="countData"
            :header-row-style="{ 'text-align': 'center' }"
            :header-cell-style="{
              background: '#FAFAFB',
              color: '#7A828A',
              fontWeight: 500,
            }"
            border
          >
            <el-table-column align="center" prop="name" :label="$t('pop_up.option')"> </el-table-column>
            <el-table-column align="center" prop="name" :label="$t('pop_up.Total_number_pieces')" width="">
              <template slot-scope="scope">
                <span v-if="scope.row.name === $t('pop_up.Temporary_parking_berth')">{{ scope.row.tempSpaceNum }}</span>
                <span v-if="scope.row.name === $t('pop_up.Fixed_berth')">{{ scope.row.fixedSpaceNum }}</span>
                <span v-if="scope.row.name === $t('pop_up.Total_number_of_berths')">{{ scope.row.totalSpaceNum }}</span>
              </template>
            </el-table-column>
            <el-table-column align="center" prop="name" :label="$t('pop_up.Remaining_number')" width="">
              <template slot-scope="scope">
                <span v-if="scope.row.name === $t('pop_up.Temporary_parking_berth')">{{
                  scope.row.remainTempSpaceNum >= 0 ? scope.row.remainTempSpaceNum : 0
                }}</span>
                <span v-if="scope.row.name === $t('pop_up.Fixed_berth')">{{
                  scope.row.remainFixedSpaceNum >= 0 ? scope.row.remainFixedSpaceNum : 0
                }}</span>
                <span v-if="scope.row.name === $t('pop_up.Total_number_of_berths')">{{
                  scope.row.remainSpaceNum >= 0 ? scope.row.remainSpaceNum : 0
                }}</span>
              </template>
            </el-table-column>
            <el-table-column align="center" prop="name" :label="$t('pop_up.Modify_the_remaining_number')" width="">
              <template slot-scope="scope">
                <div v-if="scope.row.name === $t('pop_up.Temporary_parking_berth')">
                  <el-input
                    v-model.number="tempCount"
                    @change="berthNumberSum"
                    maxlength="6"
                    @input="($event) => timeNumber($event, 'tempCount')"
                  ></el-input>
                </div>
                <div v-if="scope.row.name === $t('pop_up.Fixed_berth')">
                  <el-input
                    v-model.number="fixedCount"
                    @change="berthNumberSum"
                    maxlength="6"
                    @input="($event) => timeNumber($event, 'fixedCount')"
                  ></el-input>
                </div>
                <div v-if="scope.row.name === $t('pop_up.Total_number_of_berths')">
                  <el-input
                    disabled
                    v-model="berthNum"
                    maxlength="6"
                    oninput="value=parseInt(value.replace(/[^\d]/g,''))"
                  ></el-input>
                </div>
              </template>
            </el-table-column>
          </el-table>
        </template>
        <el-input v-else v-model="berthNum" :maxlength="6" @input="timeNumberOrigin"></el-input>
        <div slot="footer" class="dialog-footer">
          <el-button @click="dialogFormVisible = false">{{ $t('pop_up.Cancel') }}</el-button>
          <el-button type="primary" @click="subBerthNum">{{ $t('pop_up.Confirm') }}</el-button>
        </div>
      </el-dialog>
      <import-file
        ref="importFile"
        @success="
          page = 1;
          searchData();
        "
        :uploadData="{}"
        uploadUrl="/acb/2.0/bacb/park/batch/import"
        modelUrl="/acb/2.0/bacb/park/batch/downloadExcelTemplate"
        :itemName="$t('pop_up.Parking_Lot_Management')"
      />
    </div>
  </div>
</template>

<script>
import { dateFormat } from "@/common/js/public.js";
import commentSetup from "./parkList/commentSet";
import ImportFile from "@/components/uploadFileCommon/monitor";
export default {
  name: "parkManage",
  data() {
    return {
      berthNum: "",
      dialogFormVisible: false,
      temParkData: {},
      modelvalue: "",
      streetList: [],
      street: "",
      areaId: "",
      streetId: "",
      area: "",
      state1: "",
      parkType: [],
      parkScope: [],
      commercialStatus: [],
      parkData: [],
      index: 0,
      total: 0,
      parkPage: 1,
      parkPageSize: 15,
      pageSize: 15,
      page: 1,
      loading: false,
      areaList: [],
      parkList: [],
      tenantList: [],
      rsTypeList: {},
      tableCols: [
        {
          prop: "parkAlias",
          label: this.$t("list.Yard_code"),
          width: "",
        },
        {
          prop: "parkName",
          label: this.$t("list.park_name"),
          width: "",
        },
        {
          prop: "parkSystemType",
          label: this.$t("list.Duty_mode"),
          formatter: (row, column, cellValue, index) => {
            let data = {
              0: this.$t("list.Duty_AI"),
              1: this.$t("list.Duty_Someone"),
              2: this.$t("list.Duty_unmanned"),
              3: this.$t("list.Duty_long_range"),
            };
            return data[cellValue];
          },
        },
        {
          prop: "areaName",
          label: this.$t("list.region"),
        },
        {
          prop: "amount",
          label: this.$t("list.parking_number"),
        },
        {
          prop: "slaveRelationName",
          label: this.$t("list.parking_structure"),
          width: "",
        },
        {
          prop: "operationName",
          label: this.$t("list.Merchant_Name"),
          width: "",
        },
        {
          prop: "commercialStatus",
          label: this.$t("list.Commercial_status"),
          width: "",
          formatter: (row, column, cellValue, index) => {
            switch (cellValue) {
              case 1:
                return this.$t("list.Offline");
              case 2:
                return this.$t("list.commercial");
              case 3:
                return this.$t("list.test_run");
              case 4:
                return this.$t("list.Tobe_launched");
              case 5:
                return this.$t("list.Abandoned");
              default:
                return this.$t("list.unknown");
            }
          },
        },
        {
          prop: "parkAccessType",
          label: this.$t("list.Access_method"),
          width: "",
          formatter: (row, column, cellValue, index) => {
            switch (cellValue) {
              case 1:
                return this.$t("list.Access_method");
              case 2:
                return this.$t("list.Platform_to_Platform");
              case 3:
                return this.$t("list.Lightweight_box");
              default:
                return "";
            }
          },
        },
      ],
      tableData: [],
      formInline: {
        parkAlias: "",
        areaId: "",
        parkId: "",
        parkAccessType: "",
        operationId: "",
        commercialStatus: "",
      },
      fixForm: {
        remainFixedSpaceNum: "",
        remainTempSpaceNum: "",
      },
      fixedAmount: "",
      tempAmount: "",
      parkAccessType: "",
      rules: {
        remainFixedSpaceNum: [
          {
            required: true,
            message: this.$t('error_message.Please_enter_fixed_berth'),
            trigger: "blur",
          },
          {
            validator: this.checkFixBerth,
          },
        ],
        remainTempSpaceNum: [
          {
            required: true,
            message: this.$t('error_message.Please_enter_temporary_parking_space'),
            trigger: "blur",
          },
          {
            validator: this.checkTempBerth,
          },
        ],
      },
      parkIdStr: "",
      areaIds: "",
      tempCount: "",
      fixedCount: "",
      countData: [],
    };
  },
  watch: {
    modelvalue(newVal) {
      if (!newVal) {
        this.formInline.parkId = "";
        this.temParkData = {};
      }
    },
  },
  methods: {
    timeNumber(event, value) {
      event = event.replace(/[^\d]/g, "");
      this[value] = event;
    },
    timeNumberOrigin(event) {
      event = event.replace(/[^\d]/g, "");
      event = event.replace(/^0/g, "");
      this.berthNum = event;
    },
    checkFixBerth(rule, value, callback) {
      if (value > this.fixedAmount) {
        return callback(new Error(this.$t('error_message.The_quantity_cannot_exceed_the_total_number_of_fixed_berths')));
      } else {
        callback();
      }
    },
    checkTempBerth(rule, value, callback) {
      if (value > this.tempAmount) {
        return callback(new Error(this.$t('error_message.The_quantity_cannot_exceed_the_total_number_of_temporary_parking_spaces')));
      } else {
        callback();
      }
    },
    handleAreaChange() {
      let areaId = "";
      if (this.$refs.cascader) {
        areaId = this.$refs.cascader.getStreetId()
          ? this.$refs.cascader.getStreetId()
          : this.$refs.cascader.getAreaId();
      }
      this.areaIds = areaId;
    },
    berthNumberSum() {
      this.berthNum = Number(this.tempCount || 0) + Number(this.fixedCount || 0);
    },
    getParkDetail(parkId) {
      let url = "/acb/2.0/bacb/park/getParkVOById/" + parkId;
      this.$axios.get(url).then((res) => {
        if (res.state == 0) {
          const {
            remainFixedSpaceNum,
            remainTempSpaceNum,
            fixedAmount,
            tempAmount,
            amount,
            remainSpaceNum,
          } = res.value;
          this.berthNum = this.parkAccessType != 3 ? res.value.amount - res.value.occupyAmount : "";
          this.fixForm.remainFixedSpaceNum = remainFixedSpaceNum;
          this.fixForm.remainTempSpaceNum = remainTempSpaceNum;
          this.fixedAmount = fixedAmount;
          this.tempAmount = tempAmount;
          this.tempCount = "";
          this.fixedCount = "";
          this.countData = [
            Object.assign({ name: this.$t('pop_up.Temporary_parking_berth') }, { tempSpaceNum: tempAmount, remainTempSpaceNum }),
            Object.assign(
              { name: this.$t('pop_up.Fixed_berth') },
              { fixedSpaceNum: fixedAmount, remainFixedSpaceNum }
            ),
            Object.assign({ name: this.$t('pop_up.Total_number_of_berths') }, { totalSpaceNum: amount, remainSpaceNum }),
          ];
        } else {
          this.$alert(res.desc, this.$t('pop_up.Tips'), {
            confirmButtonText: this.$t('pop_up.Determine'),
          });
        }
      });
    },
    subBerthNum() {
      if (this.berthNum == 0 && this.parkAccessType != 3) {
        this.$alert(this.$t('error_message.The_berth_cannot_be_empty'), this.$t('pop_up.Tips'), {
          confirmButtonText: this.$t('pop_up.Determine'),
        });
        return;
      }
      if ((this.tempCount == "" || this.fixedCount == "") && this.parkAccessType == 3) {
        this.$alert(this.$t('error_message.The_berth_cannot_be_empty'), this.$t('pop_up.Tips'), {
          confirmButtonText: this.$t('pop_up.Determine'),
        });
        return;
      }
      if (this.tempCount > this.tempAmount) {
        this.$alert(this.$t('error_message.The_quantity_cannot_exceed_the_total_number_of_temporary_parking_spaces'), this.$t('pop_up.Tips'), {
          confirmButtonText: this.$t('pop_up.Determine'),
        });
        return;
      }
      if (this.fixedCount > this.fixedAmount) {
        this.$alert(this.$t('error_message.The_quantity_cannot_exceed_the_total_number_of_fixed_berths'), this.$t('pop_up.Tips'), {
          confirmButtonText: this.$t('pop_up.Determine'),
        });
        return;
      }
      this.berthNumFun();
    },
    berthNumFun() {
      this.$axios
        .post("/acb/2.0/bacb/park/updateParkCount", {
          data: {
            parkId: this.parkIdStr,
            count: this.berthNum,
            remainFixedSpaceNum: this.fixedCount,
            remainTempSpaceNum: this.tempCount,
          },
        })
        .then((res) => {
          this.dialogFormVisible = false;
          this.berthNum = "";
          this.tempCount = "";
          this.fixedCount = "";
          if (res.state == 0) {
            this.$alert(res.desc, this.$t('pop_up.Tips'), {
              confirmButtonText: this.$t('pop_up.Determine'),
            }).then(() => {
              this.page = 1;
              this.searchData();
            });
          } else {
            this.$alert(res.desc, this.$t('pop_up.Tips'), {
              confirmButtonText: this.$t('pop_up.Determine'),
            });
          }
        })
        .catch(() => {
          this.dialogFormVisible = false;
          this.berthNum = "";
          this.tempCount = "";
          this.fixedCount = "";
        });
    },
    setComment() {
      this.$refs.commentSet.open();
    },
    updateData(parkId) {
      this.updateLoading = true;
      this.$axios
        .post("/acb/2.0/bacb/park/sync/" + parkId, {
          data: {
            topParkId: parkId,
          },
        })
        .then((res) => {
          this.updateLoading = false;
          if (res.state == 0) {
            this.$alert(res.desc, this.$t('pop_up.Tips'), {
              confirmButtonText: this.$t('pop_up.Determine'),
            }).then(() => {
              this.page = 1;
              this.searchData();
            });
          } else {
            this.$alert(res.desc, this.$t('pop_up.Tips'), {
              confirmButtonText: this.$t('pop_up.Determine'),
            });
          }
        })
        .catch(() => {
          this.updateLoading = false;
        });
    },
    goQr(data) {
      this.$router.push({ path: "/outQrCodeDownload", query: data });
    },
    getTenantList() {
      this.$axios.get("/acb/2.0/operation/nameList").then((res) => {
        if (res.state == 0) {
          this.tenantList = res.value;
        } else {
          this.$alert(res.desc, this.$t('pop_up.Tips'), {
            confirmButtonText: this.$t('pop_up.Determine'),
          });
        }
      });
    },
    querySearchAsync(queryString, cb) {
      this.$emit("valueChange", "");

      if (/^\s*$/.test(queryString)) {
        cb();
        return;
      }
      queryString = queryString.replace(/\s/g, "");
      let url = "/acb/2.0/bacb/systems/loginUser/getParkName";
      this.$axios
        .get(url, {
          data: {
            page: 1,
            pageSize: 15,
            parkName: queryString,
            slaveRelations: "0,1",
            areaIds: this.$refs.cascader.getStreetId()
              ? this.$refs.cascader.getStreetId()
              : this.$refs.cascader.getAreaId(),
          },
        })
        .then((res) => {
          if (res.state == 0) {
            this.temParkData = res.value.length ? res.value[0] : { parkName: "", parkId: "" };
            this.formInline.parkId = "";

            cb(res.value);
          } else {
            this.$alert(res.desc, this.$t('pop_up.Tips'), {
              confirmButtonText: this.$t('pop_up.Determine'),
            });
          }
        });
    },
    handleSelect(item) {
      this.formInline.parkId = item.parkId;
    },
    setParkNull() {
      this.formInline.parkId = "";
      this.modelvalue = "";
      this.temParkData = {};
    },
    areaChange(areaId) {
      this.parkPage = 1;
      this.streetList = [];
      this.streetId = "";
      this.setParkNull();
      if (!areaId) return;
      this.getStreet(areaId);
    },
    streetChange(streetId) {
      this.parkPage = 1;
      this.setParkNull();
      this.getPark(streetId);
    },
    getStreet(areaId) {
      let url = "/acb/2.0/systems/loginUser/getChildrenArea";
      this.$axios
        .get(url, {
          data: {
            areaId,
          },
        })
        .then((res) => {
          if (res.state == 0) {
            this.streetList = res.value;
          } else {
            this.$alert(res.desc, this.$t('pop_up.Tips'), {
              confirmButtonText: this.$t('pop_up.Determine'),
            });
          }
        });
    },
    queryPark() {
      this.parkPage++;
      this.getPark(this.area);
    },
    getPark(areaId) {
      this.area = areaId;
      let url = "/acb/2.0/systems/loginUser/getParkName";
      this.$axios
        .get(url, {
          data: {
            page: this.parkPage,
            pageSize: this.parkPageSize,
            areaIds: areaId,
          },
        })
        .then((res) => {
          if (res.state == 0) {
            if (this.parkPage == 1) {
              this.$refs.select.setNull();
            }
            this.parkData = res.value.list;
          } else {
            this.$alert(res.desc, this.$t('pop_up.Tips'), {
              confirmButtonText: this.$t('pop_up.Determine'),
            });
          }
        });
    },
    queryTypeList() {
      let url = "/acb/2.0/park/getAddOption";
      this.$axios.get(url).then((res) => {
        if (res.state == 0) {
          let data = res.value;
          this.parkType = data["parkType"];
          this.commercialStatus = data["commercialStatus"];
          this.parkScope = data["parkScope"];
        } else {
          this.$alert(res.desc, this.$t('pop_up.Tips'), {
            confirmButtonText: this.$t('pop_up.Determine'),
          });
        }
      });
    },
    handleCommand2(cmd, data) {
      if (cmd == "a") {
        this.$router.push("/addNoClosePark");
      } else if (cmd == "b") {
        this.$router.push("/outAddClosePark");
      }
    },
    handleCommand(cmd, data) {
      if (cmd == "a") {
        this.$router.push({ path: "/outAddClosePark", query: data });
      } else if (cmd == "b") {
        sessionStorage.subPageAuthority = JSON.stringify(this.$route.meta.authority);
        this.$router.push({ path: "/outCloseParentDetail", query: data });
      } else if (cmd == "d") {
        this.goQr(data);
      } else if (cmd == "e") {
        this.updateData(data.parkId);
      }
      if (cmd == "c") {
        this.$setAuthority("parkConfig", this.$route.meta.authority.subPage.configure);
        console.log(this.$route.meta.authority, 11);
        this.$router.push({ path: "/outParkConfig", query: data });
      }
      if (cmd == "f") {
        this.dialogFormVisible = true;
        this.parkIdStr = data.parkId;
        this.parkAccessType = data.parkAccessType;
        this.getParkDetail(data.parkId);
      }
      if (cmd == "g") {
        this.$setAuthority("parkConfig", this.$route.meta.authority.subPage.configure);
        this.$router.push({ path: "/outParkThirdPartyConfig", query: data });
      }
      if (cmd == "h") {
        this.$setAuthority("parkConfig", this.$route.meta.authority.subPage.configure);
        this.$router.push({ path: "/outParkLightConfig", query: data });
      }
    },
    handleCurrentChange(val) {
      this.page = val;
      this.searchData();
    },
    completeValue(item) {
      this.formInline.parkId = item;
    },
    showLog(data) {
      if (data && data.length === 2) {
        let startTime = dateFormat(data[0], "yyyy-MM-dd HH:mm:ss");
        let endTime = dateFormat(data[1], "yyyy-MM-dd HH:mm:ss");
        this.formInline.startTime = startTime;
        this.formInline.endTime = endTime;
      } else {
        this.formInline.startTime = "";
        this.formInline.endTime = "";
      }
    },
    searchData() {
      this.tableData = [];
      this.loading = true;
      this.formInline.areaId = this.$refs.cascader.getStreetId()
        ? this.$refs.cascader.getStreetId()
        : this.$refs.cascader.getAreaId();
      if (!this.formInline.parkId && Object.keys(this.temParkData).length) {
        this.modelvalue = this.temParkData.parkName;
        this.formInline.parkId = this.temParkData.parkId;
        this.temParkData = {};
      }
      let url = "/acb/2.0/bacb/park/list";
      this.$axios
        .get(url, {
          data: {
            page: this.page,
            pageSize: this.pageSize,
            ...this.formInline,
          },
        })
        .then((res) => {
          this.loading = false;
          if (res.state == 0) {
            this.total = res.value.total * 1;
            this.tableData = res.value.list;
          } else {
            this.$alert(res.desc, this.$t('pop_up.Tips'), {
              confirmButtonText: this.$t('pop_up.Determine'),
            });
          }
        });
    },
    getAreaList() {
      let url = "/acb/2.0/systems/loginUser/initAreaList";
      this.$axios.get(url).then((res) => {
        if (res.state == 0) {
          if (res.value.depth == 3) {
            this.areaList = res.value.areaList;
          } else {
            this.streetList = res.value.areaList;
          }
        } else {
          this.$alert(res.desc, this.$t('pop_up.Tips'), {
            confirmButtonText: this.$t('pop_up.Determine'),
          });
        }
      });
    },
    resetForm() {
      this.formInline = {
        parkAlias: "",
        areaId: "",
        parkId: "",
        parkAccessType: "",
        operationId: "",
        commercialStatus: "",
      };
      this.$refs.cascader.clear();
      this.setParkNull();
      this.page = 1;
      this.searchData();
    },
  },
  beforeDestroy() {},
  components: {
    commentSetup,
    ImportFile,
  },
  activated() {
    this.searchData();
  },
  created() {
    this.getAreaList();
    this.queryTypeList();
    this.getTenantList();
  },
  computed: {},
  mounted() {
    if (this.$route.meta.authority) {
      let data = {};
      if (sessionStorage.getItem("authority")) {
        data = JSON.parse(sessionStorage.getItem("authority"));
      }
      data["parkList"] = this.$route.meta.authority;
      sessionStorage.setItem("authority", JSON.stringify(data));
    }
    this.searchData();
  },
};
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="stylus" rel="stylesheet/stylus">
.content {
  overflow: hidden;
  .pagerWrapper {
    text-align: right;
    margin-top: 28px;
    font-size: 12px;
    .export {
      font-size: 12px;
      .iconfont {
        margin-right: 0;
      }
    }
  }
}
.detail {
  width: 61.4%;
  height: 82.9%;
  position: absolute;
  top: 8.6%;
  left: 20.1%;
  background: #FFFFFF;
  box-shadow: 0 0 4px 1px rgba(128, 145, 165, 0.3);
  border-radius: 3px;
  z-index: 1000;
  box-sizing: border-box;
  padding: 20px 0;
  .title {
    position: absolute;
    background: #3F4A56;
    border-radius: 3px 3px 0 0;
    color: #fff;
    width: 100%;
    top: 0;
    left: 0;
    height: 40px;
    line-height: 40px;
    font-size: 14px;
    padding: 0 12px;
    box-sizing: inherit;
    z-index: 100;
    .el-icon-close {
      float: right;
      margin-top: 14px;
    }
  }

  .info {
    margin-top: 20px;
    padding: 2.1%;
    height: 100%;
    overflow: auto;
    box-sizing: inherit;
  }
}

.mask {
  background: rgba(49, 53, 65, 0.6);
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 999;
}

.color_1 {
  color: #333333;
}

.color_2 {
  color: #D9001B;
}

.el-dropdown-link {
  cursor: pointer;
}
</style>
<style>
.upBerthNum input::-webkit-outer-spin-button,
.upBerthNum input::-webkit-inner-spin-button {
  -webkit-appearance: none !important;
}
.upBerthNum input[type="number"] {
  -moz-appearance: textfield;
}
</style>
